exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contentful-page-url-js": () => import("./../../../src/pages/{contentfulPage.url}.js" /* webpackChunkName: "component---src-pages-contentful-page-url-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/Datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-event-calendar-js": () => import("./../../../src/pages/event-calendar.js" /* webpackChunkName: "component---src-pages-event-calendar-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-shop-js": () => import("./../../../src/pages/online-shop.js" /* webpackChunkName: "component---src-pages-online-shop-js" */),
  "component---src-pages-our-stories-js": () => import("./../../../src/pages/our-stories.js" /* webpackChunkName: "component---src-pages-our-stories-js" */)
}

